import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import logo from "../../assets/logo.png";

const Wrapper = styled("div")(({ theme }) => ({
  textAlign: "center",
  paddingBottom: 24,
  [theme.breakpoints.down("md")]: {
    h5: {
      fontSize: 20,
      margin: 0,
    },
  },
}));

export default function Header() {
  return (
    <Wrapper>
      <img src={logo} alt="" width={"70%"} />
      <hr />
      <Typography variant="h7" marginTop={-3} marginX={3} textAlign={"center"} >
        <b>The SOL reward pool with the richest daily return and lowest dev fee, daily income of up to 8%, and a referral bonus of up to 12% (<a href='https://banana-miner-organization.gitbook.io/banana-miner/' target='_blank' rel="noreferrer">documentation</a>)</b>
      </Typography>
      <Typography variant="inherit" marginX={6} marginTop={3} marginBottom={3} textAlign={"justify"}>
        <b>#1 - BUY BANANA</b>: Start by using your SOL to purchase bananas.<br></br>
        <b>#2 - COMPOUND</b>: To maximize your earnings, click on the "COMPOUND" button. This action will automatically reinvest your rewards back into BANANA.<br></br>
        <b>#3 - CLAIM REWARDS</b>: This will transfer your accumulated SOL rewards directly into your wallet<br></br>
      </Typography>
      <i>The key to maximizing your rewards lies in the quantity of bananas you hold and how frequently you compound them. The more bananas you accumulate and the more often you reinvest your rewards, the greater the potential for earning more rewards</i>
    </Wrapper>
  );
}
