import * as anchor from '@project-serum/anchor';
import { BN } from '@project-serum/anchor';
import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SYSVAR_RENT_PUBKEY,
  SystemProgram,
  Transaction,
  TransactionInstruction,
  TransactionSignature,
  clusterApiUrl,
} from '@solana/web3.js';

import { BigNumber } from 'bignumber.js';

import { WalletContextState } from '@solana/wallet-adapter-react';

import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { toast } from 'react-toastify';
import * as Constants from './constants';
import { IDL } from './idl';
import * as keys from './keys';
import { showToast } from './utils';

const connection = new Connection(
  'https://fragrant-alpha-dawn.solana-mainnet.quiknode.pro/7a414b6c9c623ef9164e170fc3001f54578614d6/',
);

// const connection = new Connection(clusterApiUrl(Constants.NETWORK));

export const getProgram = (wallet: any) => {
  let provider = new anchor.Provider(
    connection,
    wallet,
    anchor.Provider.defaultOptions(),
  );
  const program = new anchor.Program(IDL, Constants.PROGRAM_ID, provider);
  return program;
};

export const getGlobalStateData = async (wallet: any) => {
  const program = getProgram(wallet);
  const globalStateKey = await keys.getGlobalStateKey();
  const stateData = await program.account.globalState.fetchNullable(
    globalStateKey,
  );
  if (stateData === null) return null;
  return stateData;
};

export const getWalletSolBalance = async (wallet: any): Promise<String> => {
  if (wallet.publicKey === null || wallet.publicKey === undefined) return '0';
  let x = await connection.getBalance(wallet.publicKey);
  return new BigNumber(await connection.getBalance(wallet.publicKey))
    .div(LAMPORTS_PER_SOL)
    .toString();
};

export const getVaultSolBalance = async (wallet: any): Promise<String> => {
  if (wallet.publicKey === null || wallet.publicKey === undefined) return '0';
  const program = getProgram(wallet);
  const vaultKey = await keys.getVaultKey();
  return new BigNumber(await connection.getBalance(vaultKey))
    .div(LAMPORTS_PER_SOL)
    .toString();
};

export const getUserData = async (wallet: any): Promise<any> => {
  if (wallet.publicKey === null || wallet.publicKey === undefined) return null;
  const program = getProgram(wallet);

  const vaultKey = await keys.getVaultKey();
  const vaultBal = await connection.getBalance(vaultKey);

  let userStateKey = await keys.getUserStateKey(wallet.publicKey);

  const stateData = await program.account.userState.fetchNullable(userStateKey);
  // console.log('stateData', stateData);

  if (stateData === null) return null;

  const globalStateKey = await keys.getGlobalStateKey();
  const globalData = await program.account.globalState.fetchNullable(
    globalStateKey,
  );
  if (globalData === null) return null;
  // getEggsSinceLastHatch
  let secondsPassed = Math.min(
    globalData.eggsPerMiner.toNumber(),
    Date.now() / 1000 - stateData.lastHatchTime.toNumber(),
  );
  // console.log('secondsPassed', secondsPassed);
  // console.log(
  //   'stateData.claimedEggs.toNumber() =',
  //   stateData.claimedEggs.toNumber(),
  // );
  // console.log('secondsPassed =', secondsPassed);
  // console.log('userStateKey =', userStateKey.toBase58());
  // console.log('stateData =', stateData);
  // console.log('stateData.user =', stateData.user.toBase58());
  // console.log('stateData.miners =', stateData.miners.toNumber());
  let myEggs = stateData.claimedEggs.add(
    new BN(secondsPassed).mul(stateData.miners),
  );

  // console.log('myEggs', myEggs);
  // // console.log('myEggs =', myEggs.toNumber());
  // console.log('globalData.marketEggs =', globalData.marketEggs.toNumber());
  // console.log('new BN(vaultBal) =', new BN(vaultBal).toNumber());
  let beanRewards = calculateTrade(
    myEggs,
    globalData.marketEggs,
    new BN(vaultBal),
    globalData.psn,
    globalData.psnh,
  );

  // console.log('beanRewards', new BigNumber(beanRewards.toString()));

  return {
    miners: stateData.miners.toString(),
    beanRewards: new BigNumber(beanRewards.toString())
      .div(LAMPORTS_PER_SOL)
      .toString(),
    refAddress: stateData.referral.toBase58(),
  };
};
function calculateTrade(rt: BN, rs: BN, bs: BN, PSN: BN, PSNH: BN) {
  if (rt.toNumber() === 0) return new BN(0);
  // console.log('calcTrade');
  // console.log(rt.toNumber());
  // console.log(rs.toNumber());
  // console.log(bs.toNumber());
  // console.log(PSN.toNumber());
  // console.log(PSNH.toNumber());
  let x = PSN.mul(bs);
  let y = PSNH.add(PSN.mul(rs).add(PSNH.mul(rt)).div(rt));
  // console.log('calcTrade');
  // console.log(x.toNumber());
  // console.log(y.toNumber());
  return x.div(y);
}
export const initialize = async (
  wallet: WalletContextState,
): Promise<string | null> => {
  if (wallet.publicKey === null) throw new WalletNotConnectedError();

  const program = getProgram(wallet);

  const tx = new Transaction().add(
    await program.methods
      .initialize(wallet.publicKey) // new_authority
      .accounts({
        authority: wallet.publicKey,
        globalState: await keys.getGlobalStateKey(),
        treasury: Constants.TREASURY, //wallet.publicKey,
        vault: await keys.getVaultKey(),
        systemProgram: SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY,
      })
      .instruction(),
  );

  return await send(connection, wallet, tx);
};

export const buyEggs = async (
  wallet: WalletContextState,
  referralKey: PublicKey,
  solAmount: number,
): Promise<string | null> => {
  if (wallet.publicKey === null || wallet.publicKey === undefined)
    throw new WalletNotConnectedError();

  const program = getProgram(wallet);
  let globalStateKey = await keys.getGlobalStateKey();
  let globalData = await program.account.globalState.fetch(globalStateKey);
  let vaultKey = await keys.getVaultKey();

  let buyIx = await program.methods
    .buyEggs(new anchor.BN(solAmount * LAMPORTS_PER_SOL))
    .accounts({
      user: wallet.publicKey,
      globalState: globalStateKey,
      treasury: globalData.treasury,
      vault: vaultKey,
      userState: await keys.getUserStateKey(wallet.publicKey),
      systemProgram: SystemProgram.programId,
      rent: SYSVAR_RENT_PUBKEY,
    })
    .instruction();

  let hatchIx = await getHatchIx(program, wallet.publicKey, referralKey);
  let tx = new Transaction();
  tx.add(buyIx);
  tx.add(hatchIx);
  return await send(connection, wallet, tx);
};

export const getHatchIx = async (
  program: any,
  userKey: PublicKey,
  referralKey: PublicKey,
): Promise<TransactionInstruction> => {
  let r = referralKey;
  // console.log('referralKey.toBase58()', referralKey.toBase58());
  // console.log('r', r);
  // console.log('referralKey', referralKey);

  if (referralKey.equals(userKey)) {
    r = Constants.TREASURY;
  }

  const test = await keys.getUserStateKey(userKey);

  // console.log('await keys.getUserStateKey(userKey)', test);

  let ix = await program.methods
    .hatchEggs()
    .accounts({
      user: userKey,
      globalState: await keys.getGlobalStateKey(),
      vault: await keys.getVaultKey(),
      userState: await keys.getUserStateKey(userKey),
      referral: r,
      referralState: await keys.getUserStateKey(r),
    })
    .instruction();

  // console.log('ix', ix);
  return ix;
};

export const hatchEggs = async (
  wallet: WalletContextState,
  referralKey: PublicKey,
): Promise<string | null> => {
  if (wallet.publicKey === null || wallet.publicKey === undefined)
    throw new WalletNotConnectedError();

  const program = getProgram(wallet);
  const tx = new Transaction().add(
    await getHatchIx(program, wallet.publicKey, referralKey),
  );
  return await send(connection, wallet, tx);
};

export const sellEggs = async (
  wallet: WalletContextState,
): Promise<string | null> => {
  if (wallet.publicKey === null || wallet.publicKey === undefined)
    throw new WalletNotConnectedError();

  const program = getProgram(wallet);
  let globalStateKey = await keys.getGlobalStateKey();
  let globalData = await program.account.globalState.fetch(globalStateKey);
  let vaultKey = await keys.getVaultKey();
  const tx = new Transaction().add(
    await program.methods
      .sellEggs()
      .accounts({
        user: wallet.publicKey,
        globalState: globalStateKey,
        treasury: globalData.treasury,
        vault: vaultKey,
        userState: await keys.getUserStateKey(wallet.publicKey),
        systemProgram: SystemProgram.programId,
      })
      .instruction(),
  );
  return await send(connection, wallet, tx);
};

async function send(
  connection: Connection,
  wallet: WalletContextState,
  transaction: Transaction,
) {
  const txHash = await sendTransaction(connection, wallet, transaction);

  // console.log('txHash before launch', txHash);

  if (txHash != null) {
    let confirming_id = showToast('Confirming Transaction ...', -1, 2);
    let res = await connection.confirmTransaction(txHash);
    // console.log('res', res);
    // console.log(txHash);
    toast.dismiss(confirming_id);
    if (res.value.err) showToast('Transaction Failed', 2000, 1);
    else showToast('Transaction Confirmed', 2000);
  } else {
    showToast('Transaction Failed', 2000, 1);
  }
  return txHash;
}

export async function sendTransaction(
  connection: Connection,
  wallet: WalletContextState,
  transaction: Transaction,
) {
  if (wallet.publicKey === null || wallet.signTransaction === undefined)
    return null;
  try {
    transaction.recentBlockhash = (
      await connection.getLatestBlockhash()
    ).blockhash;
    transaction.feePayer = wallet.publicKey;
    const signedTransaction = await wallet.signTransaction(transaction);
    const rawTransaction = signedTransaction.serialize();

    showToast('Sending Transaction ...', 500);
    // notify({
    //   message: "Transaction",
    //   description: "Sending Transaction ...",
    //   duration: 0.5,
    // });

    let simulationResult = await connection.simulateTransaction(transaction);
    // console.log('simulationResult', simulationResult);
    if (simulationResult.value.err) {
      console.error('Simulation error:', simulationResult.value.err);
    }

    const txid: TransactionSignature = await connection.sendRawTransaction(
      rawTransaction,
      {
        skipPreflight: false,
        preflightCommitment: 'processed',
      },
    );

    // let transactionResponse = await connection.getTransaction(txid, {});
    // if (transactionResponse?.meta?.logMessages) {
    //   transactionResponse.meta.logMessages.forEach((log) => console.log(log));
    // }

    return txid;
  } catch (e) {
    // console.log('tx e = ', e);
    return null;
  }
}
